<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible2" size="30%" title="详情">
      <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
        <formSee :columns="addColumnsDet" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { courseList, schoolClassDel, schoolClassList, schoolClassSubmit } from '@/apis/common'
import listToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import Form from '@/components/system/form.vue'
import formSee from '@/components/system/formSee.vue'

export default {
  name: 'class',
  components: { formSee, Form, listTable, listToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      toolbar: [
        {type: 'input', label: '班级名称', model: 'name', value: ''},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      formData: {
        name: ''
      },
      loading: false,
      list: [],
      columns: [
        {label: '班级ID', prop: 'id', width: 60, type: 'text'},
        {label: '班级名称', prop: 'name', width: 100, type: 'text'},
        {label: '课程', prop: 'course_id', width: 100, type: 'json', deputy: 'course'},
        {label: '教师数量', prop: 'teacher_count', width: 100, type: 'text'},
        {label: '学生数量', prop: 'student_count', width: 100, type: 'text'},
        {label: '创建时间', prop: 'created_at', width: 150, type: 'text'},
        {label: '操作', width: 260, type: 'but', arr: [
            {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      formDrawerVisible: false,
      formDrawerVisible2: false,
      addColumns: [
        {label: '班级名称', prop: 'name', type: 'input',value: ''},
        {label: '课程', prop: 'course_id', type: 'select', value: '',options: []},
        {label: '教师', prop: 'teacher_id', type: 'teacher',value: '', options: []},
        {label: '学生', prop: 'student_id', type: 'student',value: '', options: []},
      ],
      typeForm: 'add',
      addFormData: {},
      addColumnsDet: [
        {label: '班级名称', prop: 'name', type: 'input',value: ''},
        {label: '课程', prop: 'course_id', type: 'select', value: '',options: []},
      ]
    }
  },
  mounted() {
    this.getSchoolClassList()
    this.getCourseList()
  },
  methods: {
    // 查看
    seeDetails(row) {
      this.addColumnsDet.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible2 = true
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        schoolClassDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          class_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getSchoolClassList()
        })
      }).catch(() => {})
    },
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
      })
      this.addColumns[2].options = row.teacher_list
      this.addColumns[3].options = row.student_list
      this.formDrawerVisible = true
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        class_id: n === 'edit' ? this.addFormData.id : '',
        ...e
      }
      schoolClassSubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getSchoolClassList()
      })
    },
    getCourseList() {
      courseList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
      }).then(res => {
        this.addColumns[1].options = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
        this.addColumnsDet[1].options = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.addColumns[2].options = []
      this.addColumns[3].options = []
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getSchoolClassList()
    },
    // 分页
    pageChange(e) {
      this.page = e
      this.getSchoolClassList()
    },
    getSchoolClassList() {
      this.loading = true
      schoolClassList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        page: this.page,
        pagesize: this.pageSize,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.data.map(x => {
          x.status = x.status === 1
          return x
        })
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
